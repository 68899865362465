

































































































.ad-content {
    margin: 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;

    .bag-top {
        text-align: right;
    }

    .bag-name {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
